<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-horario-dialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <horarios-data-table ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import HorariosDataTable from "../../components/shared/datatables/HorariosDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/horarios";
import CreateEditHorarioDialog from "../../components/shared/dialogs/CreateEditHorarioDialog";

export default {
  name: "index",
  components: {CreateEditHorarioDialog, BaseView, HorariosDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        // {
        //   name: 'ID',
        //   column: 'id',
        //   type: 'number',
        //   prefix: '#'
        // },
        {
          name: 'Início',
          column: 'horaInicio',
          type: 'time'
        }
      ]
    }
  },
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(aula) {
      this.item = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Horario removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover à Horario.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>