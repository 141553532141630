<template>
<v-row>
  <v-col cols="6">
    <v-text-field label="* Início"
                  dense
                  type="time"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.time.isBefore(v, value.horaFim)
                  ]"
                  v-model.trim="value.horaInicio"/>
  </v-col>
  <v-col cols="6">

    <v-text-field label="* Final"
                  dense
                  type="time"
                  :rules="[
                      this.$validators.string.required,
                      v => this.$validators.time.isAfter(v, value.horaInicio)
                  ]"
                  v-model.trim="value.horaFim"/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "HorarioForm",
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>