var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"* Início","dense":"","type":"time","rules":[
                      this.$validators.string.required,
                      function (v) { return this$1.$validators.time.isBefore(v, _vm.value.horaFim); }
                  ]},model:{value:(_vm.value.horaInicio),callback:function ($$v) {_vm.$set(_vm.value, "horaInicio", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.horaInicio"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"* Final","dense":"","type":"time","rules":[
                      this.$validators.string.required,
                      function (v) { return this$1.$validators.time.isAfter(v, _vm.value.horaInicio); }
                  ]},model:{value:(_vm.value.horaFim),callback:function ($$v) {_vm.$set(_vm.value, "horaFim", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.horaFim"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }